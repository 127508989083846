import { default as code_45of_45conductRDPbFurFEcMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/code-of-conduct.vue?macro=true";
import { default as coming_soonEpB41iOIxhMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/coming_soon.vue?macro=true";
import { default as contact4LeFZG9Eu6Meta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/contact.vue?macro=true";
import { default as faq8miDBWutYgMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/faq.vue?macro=true";
import { default as improotjeknirpSEYzmMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/improotje.vue?macro=true";
import { default as indexSAk6ucXsQXMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/index.vue?macro=true";
import { default as privacydpJDnFbzfQMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/privacy.vue?macro=true";
import { default as team5jDEInuhgbMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/team.vue?macro=true";
import { default as terms_45and_45conditionsbX2zCs9FSvMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms-and-conditions.vue?macro=true";
import { default as termsBm4XQbO4QkMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms.vue?macro=true";
export default [
  {
    name: code_45of_45conductRDPbFurFEcMeta?.name ?? "code-of-conduct",
    path: code_45of_45conductRDPbFurFEcMeta?.path ?? "/code-of-conduct",
    meta: code_45of_45conductRDPbFurFEcMeta || {},
    alias: code_45of_45conductRDPbFurFEcMeta?.alias || [],
    redirect: code_45of_45conductRDPbFurFEcMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: coming_soonEpB41iOIxhMeta?.name ?? "coming_soon",
    path: coming_soonEpB41iOIxhMeta?.path ?? "/coming_soon",
    meta: coming_soonEpB41iOIxhMeta || {},
    alias: coming_soonEpB41iOIxhMeta?.alias || [],
    redirect: coming_soonEpB41iOIxhMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/coming_soon.vue").then(m => m.default || m)
  },
  {
    name: contact4LeFZG9Eu6Meta?.name ?? "contact",
    path: contact4LeFZG9Eu6Meta?.path ?? "/contact",
    meta: contact4LeFZG9Eu6Meta || {},
    alias: contact4LeFZG9Eu6Meta?.alias || [],
    redirect: contact4LeFZG9Eu6Meta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faq8miDBWutYgMeta?.name ?? "faq",
    path: faq8miDBWutYgMeta?.path ?? "/faq",
    meta: faq8miDBWutYgMeta || {},
    alias: faq8miDBWutYgMeta?.alias || [],
    redirect: faq8miDBWutYgMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: improotjeknirpSEYzmMeta?.name ?? "improotje",
    path: improotjeknirpSEYzmMeta?.path ?? "/improotje",
    meta: improotjeknirpSEYzmMeta || {},
    alias: improotjeknirpSEYzmMeta?.alias || [],
    redirect: improotjeknirpSEYzmMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/improotje.vue").then(m => m.default || m)
  },
  {
    name: indexSAk6ucXsQXMeta?.name ?? "index",
    path: indexSAk6ucXsQXMeta?.path ?? "/",
    meta: indexSAk6ucXsQXMeta || {},
    alias: indexSAk6ucXsQXMeta?.alias || [],
    redirect: indexSAk6ucXsQXMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacydpJDnFbzfQMeta?.name ?? "privacy",
    path: privacydpJDnFbzfQMeta?.path ?? "/privacy",
    meta: privacydpJDnFbzfQMeta || {},
    alias: privacydpJDnFbzfQMeta?.alias || [],
    redirect: privacydpJDnFbzfQMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: team5jDEInuhgbMeta?.name ?? "team",
    path: team5jDEInuhgbMeta?.path ?? "/team",
    meta: team5jDEInuhgbMeta || {},
    alias: team5jDEInuhgbMeta?.alias || [],
    redirect: team5jDEInuhgbMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/team.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsbX2zCs9FSvMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsbX2zCs9FSvMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsbX2zCs9FSvMeta || {},
    alias: terms_45and_45conditionsbX2zCs9FSvMeta?.alias || [],
    redirect: terms_45and_45conditionsbX2zCs9FSvMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: termsBm4XQbO4QkMeta?.name ?? "terms",
    path: termsBm4XQbO4QkMeta?.path ?? "/terms",
    meta: termsBm4XQbO4QkMeta || {},
    alias: termsBm4XQbO4QkMeta?.alias || [],
    redirect: termsBm4XQbO4QkMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms.vue").then(m => m.default || m)
  }
]